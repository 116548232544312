import axios from 'axios'
import APP from '../config/app'
import ETC from '../config/etc'
import Util from '../utils/util'
import router from '@/router'
import store from '@/store'
import { showDialog, closeToast } from 'vant';
// import QS from 'qs'
const etcInstance = axios.create({
  // baseURL: APP.HTTP_URL,
  timeout: APP.HTTP_TIMEOUT,
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
    'X-APP-ID': '4',
    'X-APP-VER': APP.VERSION
  }
})


etcInstance.interceptors.request.use(async config => {
  const token = await store.state.token || localStorage.getItem(ETC.LOCAL_STORAGE_KEY.TOKEN)
  config.headers['X-APP-TOKEN'] = token
  console.log('%c request  url: ' + config.url + ' => params:', 'color: blue', config.data, 'token:', token)
  // 请求前设置 token
  if (config.method === 'post' && !config.isJson) {
    config.data = Util.json2formData(config.data)
  }
  console.log({config})

  return config
}, err => {
  return Promise.reject(err)
})

etcInstance.interceptors.response.use(
  response => {
    if (response.status === 200 && response.data) {
      const res = response.data
      switch (res.code) {
        case 0:
          console.log('%c response url: ' + response.config.url + ' => success:', 'color: green', response.data.data)
          return res.data
        case -1:
          console.warn('%c response url: ' + response.config.url + ' => fail:', 'color: red', res.msg)
          closeToast()
          setTimeout(() => {
            showDialog({
              message: res.msg
            })
          }, 100);
          return Promise.reject(res)
        case 200047:
        case -1000:
          // 重新登录
          console.warn('%c response url: ' + response.config.url + ' => fail:', 'color: red', res.msg)
          store.commit('setToken', '')
          localStorage.removeItem(ETC.LOCAL_STORAGE_KEY.TOKEN)
          closeToast()

          showDialog({
            message: res.msg
          }).then(() => {
            if (store.state.channel === 'MP') {
              router.push({
                path: '/third/transfer',
                query: router.currentRoute.value.query
              })
            } else {
              router.push('/login')
            }
          })
          return Promise.reject(res)
        case 33101: // 票根账户被顶掉
          closeToast()
          showDialog({
            message: res.msg
          }).then(() => {
            localStorage.removeItem(ETC.LOCAL_STORAGE_KEY.INVOICE_TICKETID)
            localStorage.removeItem(ETC.LOCAL_STORAGE_KEY.mobile)
            store.commit('setCurInvoiceMobile', '')
            store.commit('setCurInvoiceCard', [])
            router.push('/invoice/center')
          })
          return Promise.reject(res)

          default:
          console.warn('%c response url: ' + response.config.url + ' => fail:', 'color: red', res)
          return Promise.reject(res)
      }
    } else {
      console.warn('%c response url: ' + response.config.url + ' => fail:', 'color: red', response)
      closeToast()
      showDialog({
        message: `请求异常，请稍后再试(${response.code})`
      })
      return Promise.reject(new Error('request error!'))
    }
  },
  err => {
    console.warn('%c response url: ' + err.config.url + ' => fail:', 'color: red', err)
    closeToast()

    showDialog({
      message: `请求异常，请稍后再试`
    })
    return Promise.reject(err)
  }
)

export default etcInstance
