import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible/flexible'
import './assets/styles/reset.less'
import '@/assets/styles/theme.less'
import '@/assets/styles/baseUI.less'
import 'vant/lib/index.css';
import { Loading, Toast, Dialog } from 'vant';
//import VueAwesomeSwiper from 'vue-awesome-swiper'
import VConsole from 'vconsole';
//import 'swiper/css/swiper.css'
if (process.env.NODE_ENV !== 'production') {
  new VConsole();
}
createApp(App).use(store).use(router).use(Loading).use(Dialog).use(Toast).mount('#app')
