import { createStore } from 'vuex'
import { biInit } from '@/api/bi'
import ETC from '@/config/etc'
import { apiCheckBindPhone, apiLogin, apiLogout, apiAdUpload } from '@/api/user'
import { apiGetAddress, apiCancelRights } from '@/api/apply'
import { THIRD_TYPE } from '@/config/base'
let checkLoginPromise = null
export default createStore({
  state: {
    isLogin: false,
    token: '',
    navBar: {
      title: '',
      leftArrowVisible: false,
      homeVisible: false,
    },
    navBarTitle: '',
    leftArrowVisible: '',
    channel: '',
    userInfo: {
      phone: '',
    },
    addressInfo: null,
    currentOrder: {},
    previousPage: '/',
    applyInfo: {},
    entryInfo: {
      bd_vid: '',
      pl: 0,
      entry: 1000,
      url: '',
      hasUpload: false
    },
    qaList: {},
    invoiceCardList: [], // 发票卡列表
    invoicePlateList: [], // 根据车牌号排列的卡列表
    curInvoiceCard: {}, // 当前卡
    invoiceMobile: '',
    curChosenInvoiceList: [], // 已选待开票发票列表
  },
  getters: {
  },
  mutations: {
    setEntryInfo(state, payload) {
      if (payload.pl > 0) {
        localStorage.setItem(ETC.LOCAL_STORAGE_KEY.PL, payload.pl)
      }
      if (payload.entry && payload.entry !== 1000) {
        localStorage.setItem(ETC.LOCAL_STORAGE_KEY.ENTRY, payload.entry)
      }
      state.entryInfo = {
        ...state.entryInfo,
        ...payload
      }
    },
    setIsLogin(state, payload) {
      state.isLogin = payload
    },
    setToken(state, payload) {
      state.token = payload
      localStorage.setItem(ETC.LOCAL_STORAGE_KEY.TOKEN, payload)
    },
    setChannel(state, payload) {
      state.channel = payload
    },
    setUserInfo(state, payload) {
      state.userInfo = {
        ...state.userInfo,
        ...payload
      }
    },
    setApplyInfo(state, payload) {
      state.applyInfo = {
        ...state.applyInfo,
        ...payload
      }
    },
    setQaList(state, payload) {
      state.qaList = {
        ...state.qaList,
        ...payload
      }
    },
    
    setNavBarTitle(state, payload) {
      state.navBar = payload
    },
    setAddressInfo(state, payload) {
      state.addressInfo = {
        ...state.addressInfo,
        ...payload
      }
    },
    clearAddressInfo(state){
      state.addressInfo = null
    },
    setCurrentOrder(state, payload) {
      state.currentOrder = payload
    },
    setPreviousPage(state, payload) {
      state.previousPage = payload
    },
    setInvoiceCardList(state, payload) {
      state.invoiceCardList = payload
    },
    setInvoicePlateList(state, payload) {
      state.invoicePlateList = payload
    },
    setCurInvoiceCard(state, payload) {
      state.curInvoiceCard = payload
    },
    setCurInvoiceMobile(state, payload) {
      state.invoiceMobile = payload
    },
    setCurChosenInvoiceList(state, payload) {
      state.curChosenInvoiceList = payload
    },
  },
  actions: {
    // 检查登录状态
    async checkLogin({ commit, state }) {
      console.log('checkLogin begin')
      if (!state.token || !state.isLogin) {
        const localToken = localStorage.getItem(ETC.LOCAL_STORAGE_KEY.TOKEN)
        console.log('localToken ==>', localToken)
        if (localToken) {
          // 查询手机号
          commit('setToken', localToken)
          try {
            checkLoginPromise = checkLoginPromise || apiCheckBindPhone({
              third_type: THIRD_TYPE
            })
            const {third_code, uid, need_login} = await checkLoginPromise
            if (need_login) {
              commit('setIsLogin', false)
            } else {
              commit('setIsLogin', true)
              commit('setUserInfo', { uid, phone: third_code })
            }
          } catch (error) {
            console.error(error)
            commit('setIsLogin', false)
          }
          checkLoginPromise = null
        }
        
      }
      return state.isLogin
    },
    // 渠道登录初始化
    async appInit({ commit, dispatch }, payload) {
      const {third_code, uid, need_login} = await apiCheckBindPhone({
        third_type: THIRD_TYPE,
      })
      console.log('appInit', need_login)
      commit('setChannel', payload.channel)
      if (!need_login) {
        localStorage.setItem(ETC.LOCAL_STORAGE_KEY.TOKEN, payload.token || '')
        localStorage.setItem(ETC.LOCAL_STORAGE_KEY.CHANNEL, payload.channel || '') 
        commit('setUserInfo', { phone: third_code, uid })
        commit('setIsLogin', true)
      } else {
        commit('setIsLogin', false)
      }
      dispatch('uploadEntryInfo')
    },
    // 登录
    async login({ commit, dispatch }, data) {
      const { uid, token } = await apiLogin({third_type: THIRD_TYPE, ...data})
      localStorage.setItem(ETC.LOCAL_STORAGE_KEY.TOKEN, token)
      localStorage.setItem(ETC.LOCAL_STORAGE_KEY.UID, uid)
      commit('setToken', token)
      commit('setIsLogin', true)
      commit('setUserInfo', { phone: data.send_phone, uid })
      dispatch('uploadEntryInfo')
      biInit()
    },
    // 登出
    async logout({ commit }){
      try {
        await apiCancelRights({rights_code: 'rt001'}) // 登出时取消权益
      } catch (error) {
        console.error('权限取消失败', error)
      }
      await apiLogout()
      localStorage.removeItem(ETC.LOCAL_STORAGE_KEY.TOKEN)
      localStorage.removeItem(ETC.LOCAL_STORAGE_KEY.UID)
      localStorage.removeItem(ETC.LOCAL_STORAGE_KEY.INVOICE_TICKETID)
      localStorage.removeItem(ETC.LOCAL_STORAGE_KEY.mobile)
      commit('setCurInvoiceMobile', '')
      commit('setCurInvoiceCard', [])
      commit('setToken', '')
      commit('clearAddressInfo')
      commit('setUserInfo', { phone: '', uid: '' })
      commit('setIsLogin', false)
    },
    // 获取地址
    async getAddressInfo({ commit }) {
      try {
        const address = await apiGetAddress({
          third_type: THIRD_TYPE
        })
        commit('setAddressInfo', address)
      } catch (error) {
        commit('setAddressInfo', {})
      }
    },
    // 广告上传
    async uploadEntryInfo({commit, dispatch, state}) {
      console.log('state.entryInfo', state.entryInfo)
      const isLogin = await dispatch('checkLogin')
      if (isLogin && state.entryInfo.bd_vid && !state.entryInfo.hasUpload) {
        commit('setEntryInfo', {
          hasUpload: true
        })
        apiAdUpload({
          bd_vid: state.entryInfo.bd_vid,
          url: state.entryInfo.url,
          pl: state.entryInfo.pl,
          entry: state.entryInfo.entry,
        })
      }
    }
  },
  modules: {
  }
})
