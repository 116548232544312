import { createRouter, createWebHistory } from 'vue-router'
// import ETC from '@/config/etc'
import store from '@/store'
import apply from './apply'
import applyB from './applyB'
import user from './user'
import center from './center'
import third from './third'
import invoice from './invoice'
import qa from './qa'
import { biLog } from '@/api/bi' 

const routes = [
  ...center,
  ...apply,
  ...applyB,
  ...user,
  ...third,
  ...qa,
  ...invoice,
  // 首页
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'ETC助手',
      hideLeftArrow: true, // 是否隐藏左上角返回按钮
      hideHomeBtn: true, // 是否隐藏左上角主页
      requiresAuth: false, // 是否需要登录后打开
      region: '首页',
    },
    component: () => import('@/views/Home.vue')
  },
  // 404
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
let isFirstPage = true
router.beforeEach(async (to, from, next) => {
  // 改变页面title
  store.commit('setNavBarTitle', {
    title: to.meta.title || '',
    leftArrowVisible: !to.meta.hideLeftArrow,
    homeVisible: !to.meta.hideHomeBtn
  })
  document.title = to.meta.documentTitle || 'ETC助手'
  console.log('from', from)
  console.log('to', to)
  if (to.meta?.region) {
    if (!isFirstPage) {
      biLog({
        pages: from.fullPath,
        region: from.meta?.region || '',
        action_id: 214,
      })
    }
    biLog({
      pages: to.fullPath,
      region: to.meta?.region || '',
      action_id: 201,
      obj_name: '访问页面'
    })
  }
  isFirstPage = false
  
  store.commit('setPreviousPage', from.path)
  if (to.meta.requiresAuth) {
    const isLogin = await store.dispatch('checkLogin')
    console.log('isLogin ==>', isLogin)
    if (!isLogin) {
      return next({
        path: '/login',
        query: {
          redirect: to.fullPath,
          redirectQuery: JSON.stringify({
            isApply: to.query.isApply || '',
            pl: to.query.pl || '',
            abType: to.query.abType || '',
            bizExt: to.query.bizExt || '',
            applyType: to.query.applyType || '',
            orderSn: to.query.orderSn || ''
          })
        }
      })
    }
  }
  next() // 必须调用next()
})

export default router
