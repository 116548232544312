const routes = [
  // 落地页
  {
    path: '/invoice/center',
    name: 'InvoiceCenter',
    meta: {
      title: '发票首页',
      documentTitle: '发票首页',
      keepAlive: true,
      requiresAuth: true,

    },
    component: () => import('@/views/invoice/InvoiceCenter.vue')
  },
  {
    path: '/invoice/apply',
    name: 'InvoiceApply',
    meta: {
      title: '发票首页',
      documentTitle: '发票首页'
    },
    component: () => import('@/views/invoice/InvoiceApply.vue')
  },
  {
    path: '/invoice/add-card',
    name: 'AddCard',
    meta: {
      title: 'ETC助手',
      documentTitle: 'ETC助手'
    },
    component: () => import('@/views/invoice/AddCard.vue')
  },
  {
    path: '/invoice/title-list',
    name: 'TitleList',
    meta: {
      title: '抬头管理',
      documentTitle: '抬头管理'
    },
    component: () => import('@/views/invoice/TitleList.vue')
  },
  {
    path: '/invoice/title-detail',
    name: 'TitleDetail',
    meta: {
      title: '发票抬头',
      documentTitle: '发票抬头'
    },
    component: () => import('@/views/invoice/TitleDetail.vue')
  },
  {
    path: '/invoice/apply-success',
    name: 'ApplySuccess',
    meta: {
      title: '开票成功',
      documentTitle: ''
    },
    component: () => import('@/views/invoice/ApplySuccess.vue')
  },
]
export default routes