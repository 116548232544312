const ETC = {
  //  third_type
  APP_LOGIN_TYPES: {
    BAIDU: 'BAIDU'
  },
  APP_SCHEME: {
    weiche: 'bd-martin://',
    kuaigou: 'suyundriver://',
    huolala: 'huolala-driver://',
    qqmap: 'qqmap://',
    zuizhuhai: 'zhuhai.smt.com://'
  },
  LOCAL_STORAGE_KEY: {
    TOKEN: 'token',
    UID: 'uid',
    CHANNEL: 'channel',
    LOGIN_PHONE: 'login_phone', // 登录手机号码
    PL: 'pl',
    ENTRY: 'entry',
    INVOICE_TICKETID: 'INVOICE_TICKETID',
    INVOICE_MOBILE: 'INVOICE_MOBILE',
  },
  PLATE_COLOR: {
    '蓝色': 0,
    '黄色': 1,
    '黑色': 2,
    '白色': 3,
    '渐变绿色': 4,
    '黄绿双拼色': 5,
    '蓝白渐变色': 6,
    '临时牌照': 7,
    '未确定色': 9,
    '绿色': 11,
    '红色': 12
  }
}
export default ETC

