export const PRODUCT_LIST = [
  {
    id: 1,
    title: '二代升级款',
    image: 'https://wecos.etczs.net/apply-h5/product-img-01-0728.png',
    fee: '180.00',
    oriFee: '299.00',
    desc: [
      '【外观升级】全黑机身，更小巧极致',
      '【激活更简单】傻瓜式一键激活，不怕笨手笨脚，更省心',
      '【芯片升级】信号灵敏度加强，高速抬杆更快',
      '【电池升级】唤醒型双电路供电，超劲续航，电池寿命翻倍'
    ],
    swiper: [
      'https://wecos.etczs.net/apply-h5/prod-kv-2-1.jpg',
      'https://wecos.etczs.net/apply-h5/prod-kv-2-2.jpg',
      'https://wecos.etczs.net/apply-h5/prod-kv-2-3.jpg',
      'https://wecos.etczs.net/apply-h5/prod-kv-2-4.jpg',
      'https://wecos.etczs.net/apply-h5/prod-kv-2-5.jpg',
      'https://wecos.etczs.net/apply-h5/prod-kv-2-6.jpg',
      'https://wecos.etczs.net/apply-h5/prod-kv-2-7.jpg',
    ],
    rights: [
      {
        hasRight: false,
        id: 1,
        icon: 'https://wecos.etczs.net/apply-h5/product-right-icon-01.png',
        title: '加赠1年质保服务',
        subTitle: '原厂质保服务由2年升级为3年',
        packages: {
          baidu: 'CarPackage00080',
          default: 'CarPackage00072'
        }
      },
      // {
      //   hasRight: true,
      //   id: 2,
      //   icon: 'https://wecos.etczs.net/apply-h5/product-right-icon-01.png',
      //   title: '加赠3年质保服务',
      //   subTitle: '原厂质保服务延长3年，共计5年',
      //   packages: {
      //     baidu: 'CarPackage00082',
      //     default: 'CarPackage00074'
      //   }
      // },
      {
        hasRight: true,
        id: 3,
        icon: 'https://wecos.etczs.net/apply-h5/product-right-icon-02.png',
        title: '加赠100元路费券',
        subTitle: '每月领10元高速抵扣券，共100元',
        packages: {
          baidu: 'CarPackage00083',
          default: 'CarPackage00075'
        }
      }
    ],
  },
  {
    id: 2,
    title: '一代基础款',
    image: 'https://wecos.etczs.net/apply-h5/product-img-02.png',
    fee: '99.00',
    oriFee: '199.00',
    desc: [
      '【免手续费】无需绑定、充值银行卡，永久免手续费、注销费',
      '【激活简单】连接手机蓝牙，轻松激活',
      '【防盗刷】专送卡片防盗刷，防拉黑',
      '【高速发票】小程序查询通行记录，一键开票',
    ],
    swiper: [
      'https://wecos.etczs.net/apply-h5/prod-kv-1-1.jpg',
      'https://wecos.etczs.net/apply-h5/prod-kv-1-2.jpg',
      'https://wecos.etczs.net/apply-h5/prod-kv-1-3.jpg',
      'https://wecos.etczs.net/apply-h5/prod-kv-1-4.jpg',
      'https://wecos.etczs.net/apply-h5/prod-kv-1-5.jpg',
      'https://wecos.etczs.net/apply-h5/prod-kv-1-6.jpg',
    ],
    rights: [
      {
        hasRight: false,
        id: 1,
        icon: 'https://wecos.etczs.net/apply-h5/product-right-icon-01.png',
        title: '加赠1年质保服务',
        subTitle: '原厂质保服务由2年升级为3年',
        packages: {
          baidu: 'CarPackage00076',
          default: 'CarPackage00068'
        }
      },
      {
        hasRight: true,
        id: 2,
        icon: 'https://wecos.etczs.net/apply-h5/product-right-icon-01.png',
        title: '加赠3年质保服务',
        subTitle: '原厂质保服务延长3年，共计5年',
        packages: {
          baidu: 'CarPackage00078',
          default: 'CarPackage00070'
        }
      },
      {
        hasRight: true,
        id: 3,
        icon: 'https://wecos.etczs.net/apply-h5/product-right-icon-02.png',
        title: '加赠100元路费券',
        subTitle: '每月领10元高速抵扣券，共100元',
        packages: {
          baidu: 'CarPackage00079',
          default: 'CarPackage00071'
        }
      }
    ],
  },
]

export const THIRD_TYPE = 'ETC_PHONE'

export const INDEX_IMG = [
  {
    typeName: 'ETC详情',
    images: [
      {
        // width: '694px',
        // height: '675px',
        src: 'https://wecos.etczs.net/apply-h5/index-page-1-1.png'
      },
      {
        // width: '694px',
        // height: '488px',
        src: 'https://wecos.etczs.net/apply-h5/index-page-1-2.png'
      },
      {
        // width: '694px',
        // height: '518px',
        src: 'https://wecos.etczs.net/apply-h5/index-page-1-3.png'
      },
      {
        // width: '694px',
        // height: '788px',
        src: 'https://wecos.etczs.net/apply-h5/index-page-1-4.png'
      },
      {
        // width: '694px',
        // height: '856px',
        src: 'https://wecos.etczs.net/apply-h5/index-page-1-5.png'
      },
    ]
  },
  {
    typeName: '办理与费用',
    images: [
      {
        // width: '694px',
        // height: '863px',
        src: 'https://wecos.etczs.net/apply-h5/index-page-2-1.png'
      },
      {
        // width: '694px',
        // height: '526px',
        src: 'https://wecos.etczs.net/apply-h5/index-page-2-2.png'
      },
    ]
  },
  {
    typeName: '安装激活',
    images: [
      {
        // width: '694px',
        // height: '1076px',
        src: 'https://wecos.etczs.net/apply-h5/index-page-3-1.png'
      },
    ]
  },
]
export const QA_LIST = [
  { question: '哪些车辆可以办理ETC ？', answer: '支持小型汽车（9座以内）、出租车、网约车办理。' },
  { question: '是否全国通行？全国高速95折？', answer: '全国高速已实现联网，ETC设备均可畅行并享受≥5%的普惠政策，如路段还有其他优惠可叠加同享。' },
  { question: '怎么办理ETC？', answer: 'ETC助手提供免费办理与付费办理ETC方式，选择付费ETC支付后或达成免费申办条件即可享受超快发货，发货后根据包装盒内二维码指引在线上传车主本人身份证、车辆行驶证、车辆正面照片（含车牌），支持非车主代办，也可绑非车主本人微信。' },
  { question: '办理怎么收费？', answer: '选择银行补贴项目是免费的，后续使用无任何年费、手续费、服务费。选择付费办理需要支付180元，并且加送价值100元路费券或2年延保服务。' },
  { question: '什么是新一代免插卡式设备？', answer: '对比市面常见的一卡一签套餐款，ETC助手新一代免插卡式设备，采用卡签一体化设计，外观更小巧精致，搭配电池芯片升级，性能更强。' },
  { question: '办理后多久能发货？', answer: '当天16点前提交办理申请的最快当天寄出，预计2-3个工作日可收到ETC设备。' },
  { question: '如何安装与激活设备？', answer: '收到货后扫描包装盒内说明书二维码，根据指引和视频教程，上传资料、签约后，通过手机蓝牙连接设备，只需1分钟即可自助完成安装。' },
  { question: '通行后如何扣费？', answer: '出站后将自动使用微信免密支付，扣除已绑定的银行卡余额，扣费会有延迟，一般是1天内扣费，部分路段会有数天延迟。' },
  { question: '是否需要绑定银行卡？', answer: '无需绑定银行卡，支持微信支付多种扣费方式。' },
  { question: '高速通行费的发票如何开具？', answer: '关注“微信-ETC助手”公众号，打开“服务-ETC发票”，即可开具高速通行发票。' },
]

export const PG_PLATE_COLOR_LIST = {
  0: {
    color: '蓝色',
    text: '蓝牌',
  },
  1: {
    color: '黄色',
    text: '黄牌',
  },
  2: {
    color: '黑色',
    text: '黑牌',
  },
  3: {
    color: '白色',
    text: '白牌',
  },
  4: {
    color: '渐变绿色',
    text: '绿牌',
  },
  5: {
    color: '黄绿渐变色',
    text: '黄绿',
  },
  6: {
    color: '蓝白渐变色',
    text: '蓝白',
  },
  7: {
    color: '临时车牌',
    text: '临牌',
  },
  9: {
    color: '未确定',
    text: '未知',
  },
  11: {
    color: '绿色车牌',
    text: '绿牌',
  },
  12: {
    color: '红色',
    text: '红牌',
  },
}

export const PG_CARD_STATUS = {
  1: '正常',
  2: '有卡挂起',
  3: '无卡挂起',
  4: '有卡注销',
  5: '无卡注销',
  6: '卡挂失',
}