// @ts-nocheck
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import ETC from '@/config/etc'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
const baseParam = {
  system: '',
  user_tokens: '',
  entry_name: 'baidu'
}

// #公参
// action_id
// user_tokens
// network_type
// brand
// system
// pages_timestamp

// #自定义新增
// region
// obj_name
// status
// pages
// open_id（小程序open_id）
// mp_open_id（公众号open_id）
// user_token（不分渠道，全局用户唯一标识


function getBaseParams() {

  var u = navigator.userAgent;
  if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) { // 安卓手机
    baseParam.system = 'Android'
  } else if (u.indexOf('iPhone') > -1) { // 苹果手机
    baseParam.system = 'iPhone'
  }
  baseParam.user_tokens = store.state.token
  baseParam.pl = store.state.entryInfo.pl || localStorage.getItem(ETC.LOCAL_STORAGE_KEY.PL) || localStorage.getItem('applyInfo').pl || -1
  baseParam.entry_id = localStorage.getItem(ETC.LOCAL_STORAGE_KEY.ENTRY) || 1000
  baseParam.entry_name = store.state.channel === 'baidu' ? 'mini_app_baiduh5' : 'web_baiduh5'
}

export function biInit() {
  getBaseParams()
  console.log('biInit', baseParam)
}

function normalizeData(obj) {
  if (!obj) {
    return ''
  }
  return encodeURIComponent(JSON.stringify(obj))
}

export async function biLog(data) {
  try {
    Object.keys(data).forEach((key) => {
      if (isString(data[key])) {
        // 不能存在双引号，否则BI系统无法正常解释
        data[key] = data[key].replace(/"/gi, "'")
      } else if (isObject(data[key])) {
        // 对象需要格式化
        data[key] = normalizeData(data[key])
      }
    })
    const biData = {
      pages_timestamp: new Date().getTime().toString(),
      action_id: 202,
      pages: router?.currentRoute?.value?.path,
      extra_args: store.state.entryInfo,
      ...baseParam,
      ...data
    }
    console.log('Bi:', biData)
    await axios({
      url: 'https://bigdata2.etczs.net/etc-log',
      method: 'post',
      data: JSON.stringify({
        data: [biData],
        common_args: {}
      })
    })
  } catch (err){
    console.log(err)
  }
}
export default {
  biInit,
  biLog
}
